import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class GlightboxService {

  private static promise;

  constructor(@Inject(DOCUMENT) protected document: Document) {
  }

  public load() {
    // First time 'load' is called?
    if (!GlightboxService.promise) {

      // Make promise to load
      GlightboxService.promise = new Promise(resolve => {

        let node = this.document.createElement('script');
        node.type = 'text/javascript';
        node.src = "/assets/js/glightbox-3.3.0/dist/js/glightbox.min.js";
        node.async = true;
        this.document.getElementsByTagName('head')[0].appendChild(node);

        //now load the css
        let css = this.document.createElement('link');
        css.rel = 'stylesheet';
        css.href = "/assets/js/glightbox-3.3.0/dist/css/glightbox.min.css";
        this.document.getElementsByTagName('head')[0].appendChild(css);

        //wait until GLightbox is loaded
        node.onload = () => {
          resolve('glightbox loaded');
        };

      });
    }

    // Always return promise. When 'load' is called many times, the promise is already resolved.
    return GlightboxService.promise;
  }

}

